.sidebar-accordian-heading-btn{
color: white;
font-size: 0.75rem;
    padding: 0.75rem 0rem;
    outline-style: none;
  

}

.sidebar-accordian-heading-btn:hover{

    color: white;
    text-decoration: underline;

}

.sidebar-accordian-heading-btn:focus{

    box-shadow: none;
}

.sidebar-accordian-heading{
    color: white;
    font-size: 0.75rem;
        padding: 0.75rem;
    
    }

.sidebar-accordian-heading:hover{
    color: white;
    

}

.background-color-card-header{
background-color: #343A40;


}


.background-color-card-header-green{

    background-color: #08B394;

}

.arrow-sidebar{

    width: 12px;
    height: 12px;
    margin-bottom: 5px;
    margin-right: 12px;
}
.arrow-color{

    color: white;
}

.card-header{

    padding-left: 1rem;
    padding-right: 0.5rem;
}

.sidebar-hr{

    margin: 0%;
    border-top: 1px solid white;
}

.Sidebar{

    z-index: 105;
 
}