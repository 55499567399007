.icon-size{
  
    height: 70px;
    
}

@media (min-width: 768px) {
.border-icon{
    border-right-style: solid;
  border-right-width: 2px;
  border-right-color: #99ccee;
}
}

.icon-animation{

    cursor: pointer;
   
}

.icon-animation:hover{

    
    
    transform: scale(1.15);
}

@media (max-width: 768px) {
    .border-icon{
        border-bottom-style: solid;
      border-bottom-width: 2px;
      border-bottom-color: #99ccee;
      margin-top: 10px;


     
    }
    .border-centerlogin{
        margin-top: 10px;

    }



}

