
  
  .text-small {
    font-size: 0.9rem;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  a:hover, a:focus {
    text-decoration: none;
  }
 

  footer {
    background: #212529;
  }
  

    body, html {
      height: 100%;
    }