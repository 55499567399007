.Aboutussliderimg{


    width: 70%;
    height: 70%;
    border-radius: 50%;
    border: 3px solid

}

.eventoutercontainer{
    border-width: 2px;
    border-color: #9ce;
    border-style: solid;
    width: 60%;

}


.eventheader{
    border-bottom-width: 2px;
    border-bottom-color: #9ce;
    border-bottom-style: solid;
    background-color: #99ccee;
}
.eventheadertext{


}

.fialogoa{


height: 350px;
}

@media (max-width: 768px) {
   
    .fialogoa{
       
        height: 250px;

    }
    }