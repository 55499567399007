
.btn-homepage {
    border-color: #9ce;
}

.btn-homepage:hover {
    background-color: #9ce;
}

@media (min-width: 768px) {
    .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
      
    }
  }

  @media (max-width: 768px) {

  
  .navbarlogo{
    width: 70%;

  }
  .navbar-brand{
    display: inline;
    text-align: center;
    
   
  }
  .dropdown-menu{
    background-color: #e3f2fd;
  }

  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
    100% {
      transform:translateY(0rem);
      opacity: 1;
    }
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
  }
  
  .slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
  }
  
  .navbar-margin{
    margin-bottom: 125px;
  }