
  .btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
  }
  
  .btn-google {
    color: white !important;
    background-color: #ea4335;
  }
  
  .btn-facebook {
    color: white !important;
    background-color: #3b5998;
  }

.background-card  {
  
    
}

.login-form-text {
    font-weight: 800;
}
.login-hr{
    border-style: solid none;
    border-width: 3px;
    color: white;
}

