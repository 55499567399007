.column-width{

    width: 25%;
}
.marksheet-label-size{
    font-size: 20px;
}

.text-marksheet{
    color: black;

}
.studentinfo-img{

    border: 2px solid black;
    border-radius: 5%;
    width: 40%;

}

@media (max-width: 768px) {
    .studentinfo-img{

        margin-top: 35px;
    
    }
    .certified-img{
        margin-bottom: 35px;
    }


}



.certified-img{

    width: 70%;

}

.table-blue{

    border-color: #7abaff;
    background-color: #9ce;
}

.margin-table{

    margin: 0% 3% 0% 3%;
}
