.homepageslider{
  
    margin-top: -15px;

}
.slider-height{
    height: 450px;
    width: 100%;
}


@media (max-width: 768px) {
   

.slider-height{
    height: auto;
}

}


