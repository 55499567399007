

.enrollment-number{

    width: 50%;


}


.btn-verify {
    border-color: #9ce;
}

.btn-verify:hover {
    background-color: #9ce;
}

.fialogo {
    width: 150px;
    height: 150px;
}

