.handle {
    content: '';
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    background-color: grey;
    height: 150px;
    width: 10px;
    right: -20px;
    border-radius: 10px;

}

.right-handle-wrapper {
    position: absolute;
    right: 14px;
    top: 50%;
}
.arrow-right {

    position: absolute;
    top: 50%;

    transform: translateY(-50%);

    left: 11px;
    width: 18px;
}


.slider-pannel {
    position: fixed;
    left: 0;
    top: 73px;
    height: 85vh;
    background-color: white;
    z-index: 99;
    padding: 8px;
    transition: width 0.5s;
    height: 80vh;

}




.Sidebar-dashboard{

    background-color: white;
    padding: 2% 2% 3% 3%;

}

.af{
    position: absolute;
    z-index: 105;
    position: fixed;
    height: 100vh;
}
