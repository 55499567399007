.Welcome_heading{

margin: auto;
}

.contact-formatting h2{

    font-weight: 100;
}



@media (max-width: 768px) {
    
    .Welcome_heading{
        font-size: 1.6rem;
        font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
        margin: 10px;
    }

}