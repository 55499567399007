.table-header{

    background-color: #99ccee;
}
.table-format{
    border-width: 2px;
    border-color: #9ce;
    border-style: solid;
}

.btn-franchisee-form{
    border-color: #9ce;
}

.btn-franchisee-form:hover {
    background-color: #9ce;
}

.text-howtoget{
    text-align: justify;
}

@media (max-width: 768px) {
    .text-howtoget{
        text-align: left;
    }
}
