.studentinfo-img{

    border: 2px solid black;
    border-radius: 5%;
    width: 40%;

}

.certified-img{

    width: 70%;

}

.table-blue{

    border-color: #7abaff;
    background-color: #9ce;
}

.margin-table{

    margin: 0% 3% 0% 3%;
}
