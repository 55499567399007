.map-container-6{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    }
    .map-container-6 iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
    }

    .contactus-icon{
        color: blue;
    }

    .section-padding{
        padding: 40px 80px 80px 80px;
    }
    .contact-text-heading{
        padding-top: 8px;
        padding-bottom: 16px;
    }

    @media (max-width: 768px) {
   .section-padding{
       padding: 10px;
   }
   .contact-text-heading{
    padding-top: 8px;
    padding-bottom: 16px;
    }
    }
   