
.carousel-indicators li {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #9ce;
   
}


.vision-slider-margin{

    margin-top: 10%;
}

@media (max-width: 768px) {
  
    .padding-vision{
        padding: 0px 2px 0px 2px;
    }
}


@media (min-width: 768px) {
.padding-vision{
    padding: 0px 48px 0px 48px;
}
}